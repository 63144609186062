import * as R from 'ramda';
import styled from 'styled-components';
import React, { Fragment } from 'react';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { formatTotal, convertCentsToDollars } from 'poly-utils';
import { Grid } from 'poly-book';

import {
  spendReportHierarchyPropTypes,
  spendReportTableHeaderPropTypes,
} from './prop-types.js';
import { MoneyGridCell } from './components.js';
import { spendReportCellType } from './constants.js';
import { useTableProps } from './useTableProps.js';

export const GridRow = styled(Grid.Row)`
  border-bottom: 1px solid #f5f2f2;
  background-color: ${R.propOr('white', 'bgColor')} !important;
`;

const PropertyGridRow = styled(GridRow)`
  padding-top: 8px !important;
  padding-bottom: 8px !important;
`;

const BoldGridCell = styled(Grid.Cell)`
  font-size: 16px;
  font-weight: bold;
`;

const TotalInvoiceCell = styled(BoldGridCell)`
  grid-row-start: 1;
  grid-column-start: ${R.prop('start')};
  justify-content: end;
`;

const TotalCostCell = styled(BoldGridCell)`
  grid-row-start: 1;
  grid-column-start: 9;
  justify-content: end;
`;

const PropertyNameCell = styled(Grid.Cell)`
  grid-column-start: 1;
  grid-column-end: 7;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

// getRowKey :: { _id: ID, projectNumber: String, rowIndex: Int } -> String
const getRowKey = R.compose(
  R.join('_'),
  R.juxt([
    R.propOr('-', '_id'),
    R.propOr('-', 'projectNumber'),
    R.propOr('-', 'rowIndex'),
  ]),
);

function PropertyWithInvoicesSection({
  columns,
  headers,
  propertyId,
  propertyName,
  propertyInvoicesReport,
}) {
  return (
    <Fragment key={`property_${propertyId}`}>
      <PropertyGridRow bgColor="#E6F7FF">
        <PropertyNameCell>{propertyName}</PropertyNameCell>
      </PropertyGridRow>
      {propertyInvoicesReport.map((invoice, rowIndex) => (
        <GridRow key={getRowKey({ ...invoice, rowIndex })}>
          {columns.map((column, index) =>
            headers[index].type === spendReportCellType.money ? (
              <MoneyGridCell
                key={`cell_${invoice._id}_${headers[index]?.title}`}
              >
                {column(invoice)}
              </MoneyGridCell>
            ) : (
              <Grid.Cell key={`cell_${invoice._id}_${headers[index]?.title}`}>
                {column(invoice)}
              </Grid.Cell>
            ),
          )}
        </GridRow>
      ))}
    </Fragment>
  );
}

PropertyWithInvoicesSection.propTypes = {
  columns: arrayOf(func),
  headers: arrayOf(shape),
  propertyName: string,
  propertyId: string,
  propertyInvoicesReport: arrayOf(
    shape({
      projectNumber: string,
      file: string,
      invoiceNumber: string,
      date: string,
      supplier: string,
      serviceType: string,
      total: number,
      clientInvoicesAmount: number,
    }),
  ),
};

function SpendReportTableComponent({
  rows,
  columns,
  headers,
  gridColumns,
  isTransparent,
}) {
  return (
    <TableWrapper>
      <Grid columns={gridColumns}>
        <Grid.Body>
          {rows.map(
            ({
              propertyId,
              propertyName,
              invoicesTotal,
              childProperties,
              clientInvoicesTotal,
              propertyInvoicesReport,
            }) => (
              <Fragment key={`property_${propertyId}`}>
                <PropertyWithInvoicesSection
                  columns={columns}
                  headers={headers}
                  propertyId={propertyId}
                  propertyName={propertyName}
                  propertyInvoicesReport={propertyInvoicesReport}
                />
                {!R.isEmpty(childProperties) &&
                  childProperties.map((childProperty) => (
                    <PropertyWithInvoicesSection
                      key={`child-property-${childProperty?.propertyId}`}
                      columns={columns}
                      headers={headers}
                      {...childProperty}
                    />
                  ))}
                <GridRow>
                  {isTransparent && (
                    <TotalCostCell>
                      {formatTotal(convertCentsToDollars(invoicesTotal))}
                    </TotalCostCell>
                  )}
                  <TotalInvoiceCell start={headers.length}>
                    {formatTotal(convertCentsToDollars(clientInvoicesTotal))}
                  </TotalInvoiceCell>
                </GridRow>
              </Fragment>
            ),
          )}
        </Grid.Body>
      </Grid>
    </TableWrapper>
  );
}

SpendReportTableComponent.propTypes = {
  gridColumns: string,
  isTransparent: bool,
  columns: arrayOf(func),
  headers: arrayOf(shape(spendReportTableHeaderPropTypes)),
  rows: arrayOf(
    shape({
      propertyId: string,
      propertyName: string,
      invoicesTotal: number,
      clientInvoicesTotal: number,
      propertyInvoicesReport: arrayOf(
        shape({
          file: string,
          date: string,
          total: number,
          supplier: string,
          serviceType: string,
          projectNumber: string,
          invoiceNumber: string,
          clientInvoicesAmount: number,
        }),
      ),
    }),
  ),
};

export function SpendReportTable({ reports, isTransparent, isPrint }) {
  const tableProps = useTableProps(reports, isTransparent, isPrint);

  return <SpendReportTableComponent {...tableProps} />;
}

SpendReportTable.propTypes = {
  isPrint: bool,
  isTransparent: bool,
  reports: arrayOf(shape(spendReportHierarchyPropTypes)),
};
