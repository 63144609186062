import React from 'react';
import { instanceOf, func, bool, string } from 'prop-types';
import { S, Holder, Input } from 'poly-book';

import { DatePicker } from './DatePiker.js';

export function DateRangePiker({
  isLabel,
  endDate,
  onChange,
  startDate,
  className,
}) {
  return (
    <Holder margin={30} center className={className}>
      {isLabel && (
        <S type="title" uppercase>
          Date Selector:
        </S>
      )}
      <Holder margin={10} center>
        <S type="badge">From</S>
        <DatePicker
          maxDate={endDate}
          selected={startDate}
          dateFormat="MM-dd-yyyy"
          placeholderText="Select date"
          customInput={<Input name="startDate" />}
          onChange={(date) => onChange({ endDate, startDate: date })}
        />
        <S type="badge">to</S>
        <DatePicker
          selected={endDate}
          minDate={startDate}
          dateFormat="MM-dd-yyyy"
          placeholderText="Select date"
          customInput={<Input name="endDate" />}
          onChange={(date) => onChange({ startDate, endDate: date })}
        />
      </Holder>
    </Holder>
  );
}

DateRangePiker.defaultProps = {
  isLabel: true,
};

DateRangePiker.propTypes = {
  isLabel: bool,
  className: string,
  onChange: func.isRequired,
  endDate: instanceOf(Date),
  startDate: instanceOf(Date),
};
