import * as R from 'ramda';
import { getPropertiesDeepByHierarchyNodeR, isNilOrEmpty } from 'poly-utils';
import { entityToOptionByLabelPath } from 'poly-client-utils';
import { ProjectSpendTypes } from 'poly-constants';

// isEmptyHierarchyNodeBranches :: [PropertyHierarchySpendReportResult] -> Boolean
export const isEmptyHierarchyNodeBranches = R.compose(
  R.equals(0),
  R.length,
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

const ALL_PROPERTIES = 'ALL_PROPERTIES';

export const ALL_SELECTED_OPTION = {
  value: ALL_PROPERTIES,
  label: 'All properties',
};

// isAllOption :: Option -> Boolean
export const isAllOption = R.pathEq(['value'], ALL_PROPERTIES);

// rejectAllOption :: [Option] -> [Option]
export const rejectAllOption = R.reject(isAllOption);

// getOptionsValues :: Option -> String
export const getOptionsValues = R.map(R.prop('value'));

// toMutationInput :: Filters -> ClientSpendReportInput
export const toMutationInput = R.compose(
  R.over(
    R.lensProp('spendType'),
    R.ifElse(
      isNilOrEmpty,
      R.always(null),
      R.compose(
        R.when(
          R.propEq(0, ProjectSpendTypes.RECURRING_SERVICE),
          R.append(ProjectSpendTypes.PREVENTIVE_REPAIR),
        ),
        R.of,
        R.prop('value'),
      ),
    ),
  ),
  R.when(R.propSatisfies(isNilOrEmpty, 'propertyId'), R.dissoc('propertyId')),
  R.evolve({
    propertyId: R.compose(getOptionsValues, rejectAllOption),
  }),
);

// preparePropertyOptions :: [Property] -> [PropertySelectOption]
// PropertySelectOption = {
//    value: ID
//    label: String
//    isMaster: Boolean
//    masterProperty: Property
// }
export const preparePropertyOptions = R.compose(
  R.map(R.mergeAll),
  R.map(
    R.juxt([
      entityToOptionByLabelPath(['name']),
      R.pick(['isMaster', 'masterProperty', 'childProperties']),
    ]),
  ),
);
