import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputWithIcon, IconButton, Icon } from 'poly-book';
import { useTableSearch } from '../hooks/useTableSearch.js';

const StyledIconButton = styled(IconButton)`
  > svg {
    ${({ hide }) => hide && 'opacity: 0;'};
    transition: opacity 0.1s;
  }
`;

export function TableSearchComponent({
  disabled,
  setTableSearchString,
  setPaginationCurrentPage,
  tableSearchString,
}) {
  const {
    setRef,
    onChange,
    onClear,
    hideCloseIcon,
    disabled: isSearchDisabled,
  } = useTableSearch({
    setTableSearchString,
    setPaginationCurrentPage,
    tableSearchString,
    disabled,
  });

  return (
    <InputWithIcon
      name="search"
      placeholder="Search"
      icon={
        <StyledIconButton
          onClick={onClear}
          hide={hideCloseIcon}
          key="attachment"
        >
          <Icon name="close" fill="#3a3d46" dimensions={{ width: 10 }} />
        </StyledIconButton>
      }
      iconAlign="right"
      style={{ width: 200 }}
      {...{ onChange, disabled: isSearchDisabled }}
      autoFocus
      ref={(ref) => setRef(ref)}
    />
  );
}

TableSearchComponent.propTypes = {
  disabled: PropTypes.bool,
  setTableSearchString: PropTypes.func,
  setPaginationCurrentPage: PropTypes.func,
  tableSearchString: PropTypes.string,
};

TableSearchComponent.defaultProps = {
  disabled: false,
};
