import * as R from 'ramda';
import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';
import {
  DEFAULT_PROPERTY_HIERARCHY_NAME,
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
} from 'poly-constants';

import {
  TextCenter,
  GridScrollBody,
  SpendReportHierarchyWrapper,
} from './components.js';
import { SpendReportTable } from './SpendReportTable.js';
import { spendReportHierarchyPropTypes } from './prop-types.js';
import { SpendReportTableHeader } from './SpendReportTableHeader.js';
import { SpendReportTableFooter } from './SpendReportTableFooter.js';
import { SpendReportHierarchyTree } from './SpendReportHierarchyTree.js';
import { isEmptyHierarchyNodeBranches } from './helpers.js';
import { useTableProps } from './useTableProps.js';

// isDefaultHierarchy :: [{ name: String }] -> Boolean
const isDefaultHierarchy = R.both(
  R.compose(R.equals(1), R.length),
  R.pathEq(['0', 'name'], DEFAULT_PROPERTY_HIERARCHY_NAME),
);

export function SpendReportHierarchy({
  reports,
  isPrint,
  hierarchyId,
  isTransparent,
  isClientWithoutAnyHierarchy,
}) {
  const tableProps = useTableProps(reports, isTransparent, isPrint);

  if (isEmptyHierarchyNodeBranches(reports)) {
    return <TextCenter>No data to report</TextCenter>;
  }

  return (
    <SpendReportHierarchyWrapper>
      <SpendReportTableHeader {...tableProps} />
      <GridScrollBody>
        {isClientWithoutAnyHierarchy ||
        (isDefaultHierarchy(reports) &&
          hierarchyId === DEFAULT_PROPERTY_HIERARCHY_OPTION) ? (
          <SpendReportTable
            {...tableProps}
            isPrint={isPrint}
            reports={reports[0].properties}
          />
        ) : (
          reports.map((report) => (
            <SpendReportHierarchyTree
              level={0}
              key={report.name}
              {...{ report, isTransparent, isPrint, ...tableProps }}
            />
          ))
        )}
      </GridScrollBody>
      <SpendReportTableFooter {...tableProps} reports={reports} />
    </SpendReportHierarchyWrapper>
  );
}

SpendReportHierarchy.propTypes = {
  isPrint: bool,
  hierarchyId: string,
  isTransparent: bool,
  isClientWithoutAnyHierarchy: bool,
  reports: arrayOf(shape(spendReportHierarchyPropTypes)),
};
