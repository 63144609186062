import React, { useState } from 'react';
import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import PropTypes, { bool, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { CREATE_UPDATE_PERMISSION } from 'poly-security';
import {
  InputWithIcon,
  Conversation,
  IconButton,
  Button,
  Holder,
  Widget,
  Icon,
  S,
  HiddenInput,
} from 'poly-book';
import { ensureIsDate, removeFileExtension } from 'poly-utils';
import { format, isToday, isYesterday, startOfDay } from 'date-fns';
import { Paginator } from '../Paginator/Paginator.js';
import { ButtonLoader } from '../ButtonLoader.js';

import { UpdateSection } from './UpdatesItem.js';
import { EditFileModalComp } from '../Modal/EditFileModalComp.js';
import { isNotNilOrEmpty } from '../../utils/general.js';

const fileClicker = (event) => {
  event.preventDefault();
  window.document.getElementById('file').click();
};

const InputFile = styled(HiddenInput).attrs(() => ({
  id: 'file',
  type: 'file',
  tabIndex: -1,
}))``;

const Form = styled(Holder.withComponent('form'))`
  align-items: flex-start;

  > ${Button} {
    min-height: 40px;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: ${({ scrollbar }) => (scrollbar ? 25 : 10)}px;
  top: 10px;
`;

const Updates = styled(Widget.Item)`
  height: 100%;
  display: grid;
  grid-row-gap: 20px;

  ${({ withForm }) => withForm && 'grid-template-rows: auto 1fr'};
`;

const Wrapper = styled.section`
  position: relative;

  @media (max-width: 1024px) {
    min-height: 500px;
  }
`;

const Attachments = styled(Conversation.Item)`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(3, 1fr);

  > ${Button} {
    justify-content: center;
  }
`;

function TextArea({ scrollbar, onTextAreaChange }) {
  return (
    <InputWithIcon
      type="textarea"
      name="message"
      placeholder="Enter an Update"
      onChange={onTextAreaChange}
      style={{
        height: 40,
        minHeight: 40,
        padding: '8px 35px 8px 15px',
        overflowY: 'hidden',
      }}
      icon={
        <StyledIconButton
          key="attachment"
          onClick={fileClicker}
          {...{ scrollbar }}
        >
          <Icon name="attachment" fill="#9dacdc" dimensions={{ height: 18 }} />
        </StyledIconButton>
      }
      iconAlign="right"
      autoFocus
    />
  );
}

TextArea.propTypes = {
  scrollbar: PropTypes.bool.isRequired,
  onTextAreaChange: PropTypes.func.isRequired,
};

function EditFileNameModal({
  selectedFile,
  closeSelectedFile,
  onFileConfirmed,
}) {
  const [fileName, setFileName] = useState(
    removeFileExtension(selectedFile.name),
  );
  const [error, setError] = useState(null);

  const onConfirmWithValidation = () => {
    if (!fileName) {
      return setError('File Name is required');
    }
    onFileConfirmed({
      fileName,
      upload: selectedFile,
      key: uuidV4(),
    });
    return closeSelectedFile();
  };

  const onChange = (e) => {
    const { value } = e.target;
    setFileName(value);
  };

  return (
    <EditFileModalComp
      onConfirm={onConfirmWithValidation}
      closeModal={closeSelectedFile}
      fileName={fileName}
      onChange={onChange}
      errors={{ fileName: error }}
      show
    />
  );
}

EditFileNameModal.propTypes = {
  selectedFile: shape({
    name: string.isRequired,
  }).isRequired,
  closeSelectedFile: func,
  onFileConfirmed: func,
};

function AddUpdateForm({
  onTextAreaChange,
  onFileSelect,
  scrollbar,
  addUpdate,
  loading,
  selectedFile,
  closeSelectedFile,
  onFileConfirmed,
}) {
  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_UPDATE_PERMISSION,
  );

  return ifHasPermission ? (
    <Form onSubmit={addUpdate}>
      <InputFile onChange={onFileSelect} />
      <TextArea {...{ scrollbar, onTextAreaChange }} />
      <Button type="submit" mode="orange" disabled={loading}>
        {loading && <ButtonLoader />}
        <S type="title">Update</S>
      </Button>
      {selectedFile && (
        <EditFileNameModal
          selectedFile={selectedFile}
          closeSelectedFile={closeSelectedFile}
          onFileConfirmed={onFileConfirmed}
          errors={{}}
          show
        />
      )}
    </Form>
  ) : null;
}

AddUpdateForm.propTypes = {
  onTextAreaChange: PropTypes.func,
  onFileSelect: PropTypes.func,
  addUpdate: PropTypes.func,
  loading: PropTypes.bool,
  scrollbar: PropTypes.bool,
  onFileConfirmed: PropTypes.func,
  closeSelectedFile: PropTypes.func,
  selectedFile: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

// transformUpdatesByDate :: [Update] -> Object
const transformUpdatesByDate = (updates) =>
  R.compose(
    R.groupBy((update) => {
      if (isToday(ensureIsDate(update.createdAt))) {
        return 'today';
      }
      if (isYesterday(ensureIsDate(update.createdAt))) {
        return 'yesterday';
      }
      return format(startOfDay(ensureIsDate(update.createdAt)), 'MM-dd-yyyy');
    }),
    R.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  )(updates);

export function UpdatesTabBase({
  attachments,
  selectedFile,
  onFileSelect,
  closeSelectedFile,
  onFileConfirmed,
  onFileRemove,
  onTextAreaChange,
  onImageClick,
  onUserClick,
  addUpdate,
  updates,
  scrollbar,
  loading,
  total,
  updatesWithTitle,
}) {
  const updatesTransformed = transformUpdatesByDate(updates);

  return (
    <Updates withForm>
      <AddUpdateForm
        {...{
          onTextAreaChange,
          selectedFile,
          onFileSelect,
          closeSelectedFile,
          onFileConfirmed,
          scrollbar,
          addUpdate,
          loading,
        }}
      />
      <Wrapper>
        {attachments.length > 0 && (
          <Attachments>
            {attachments.map(({ key, fileName }) => (
              <Button
                {...{ key }}
                title="Click to Remove File"
                onClick={() => onFileRemove(key)}
              >
                <S type="title">{fileName}</S>
              </Button>
            ))}
          </Attachments>
        )}
        {Object.keys(updatesTransformed).map((key) => (
          <UpdateSection
            updatesWithTitle={updatesWithTitle}
            onUserClick={onUserClick}
            onImageClick={onImageClick}
            key={key}
            title={key}
            updates={updatesTransformed[key]}
          />
        ))}
        {isNotNilOrEmpty(updates) && <Paginator total={total} />}
      </Wrapper>
    </Updates>
  );
}

UpdatesTabBase.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  updates: PropTypes.array.isRequired,
  attachments: PropTypes.array.isRequired,
  addUpdate: PropTypes.func.isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  onTextAreaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  scrollbar: PropTypes.bool.isRequired,
  total: PropTypes.number,
  selectedFile: shape({
    name: string.isRequired,
  }),
  closeSelectedFile: func,
  onFileConfirmed: func,
  onImageClick: func,
  onUserClick: func,
  updatesWithTitle: bool,
};
